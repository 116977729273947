import React from 'react'
import styled from 'styled-components'

const StyledFieldset = styled.fieldset`
  margin-bottom: ${props => props.theme.remScale[6]};
  padding: 0;
  border: 0;
  border-top: 1px solid ${props => props.theme.neutral5};

  header p {
    color: ${props => props.theme.neutral2};
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    section {
      display: flex;

      header {
        flex: 1;
      }

      > div {
        flex: 1;
        padding-top: 1.5rem;
      }
    }
  }
`

export const Fieldset = ({ legend, description, children }) => (
  <StyledFieldset>
    <section>
      <header>
        <legend className="hero">{legend}</legend>
        <p>{description}</p>
      </header>
      <div>{children}</div>
    </section>
  </StyledFieldset>
)
