import React, { Component } from 'react'
import { ScrollLink } from 'react-scroll'

import { Button } from './styles/Button'

class ScrollButtonClass extends Component {
  render() {
    return <Button {...this.props}>{this.props.children}</Button>
  }
}

export const ScrollButton = ScrollLink(ScrollButtonClass)
