import React from 'react'
import styled from 'styled-components'

import { Container } from './styles/Container'

const StyledSection = styled.section`
  padding-top: ${props => props.theme.remScale[9]};
  padding-bottom: ${props => props.theme.remScale[9]};
`

export const Section = props => (
  <StyledSection {...props}>
    <Container>{props.children}</Container>
  </StyledSection>
)
