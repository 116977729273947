import React from 'react'
import { graphql, Link } from 'gatsby'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { theme } from '../components/styles/theme'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { Container } from '../components/styles/Container'
import { Button } from '../components/styles/Button'
import { ScrollButton } from '../components/ScrollButton'
import { Section } from '../components/Section'
import { EvaluationForm } from '../components/EvaluationForm'
import trianglify from '../images/trianglify.svg'
import programming from '../images/undraw_programming_2svr.svg'
import businessPlan from '../images/undraw_business_plan_5i9d.svg'
import logo from '../images/level-up-logo.svg'

const IntroSection = styled.section`
  background-image: url(${trianglify});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  div {
    position: relative;
    padding-top: ${props => props.theme.remScale[14]};
    padding-bottom: ${props => props.theme.remScale[9]};

    @media (min-width: ${props => props.theme.breakpoints.md}) {
      &::after {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        pointer-events: none;
        opacity: 0.6;
        background-image: url(${programming});
        background-repeat: no-repeat;
        background-position: right 60%;
        background-size: 30%;
      }
    }

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      padding-top: calc(${props => props.theme.remScale[12]} + 110px);
      padding-bottom: ${props => props.theme.remScale[12]};
    }
  }

  @media (min-width: ${props => props.theme.breakpoints.md}) {
    h1 {
      max-width: 70%;
    }
  }
`

const PitchSection = styled(Section)`
  > div {
    display: flex;
    flex-direction: column;

    @media (min-width: ${props => props.theme.breakpoints.lg}) {
      flex-direction: row;
    }
  }
`

const EvaluationIntro = styled.div`
  @media (min-width: ${props => props.theme.breakpoints.md}) {
    background-image: url(${businessPlan});
    background-repeat: no-repeat;
    background-position: center right;
    background-size: 30%;
  }
`

const Sidebar = styled.div`
  padding-top: ${props => props.theme.remScale[8]};

  @media (min-width: ${props => props.theme.breakpoints.lg}) {
    align-self: center;
    margin-left: ${props => props.theme.remScale[7]};
  }
`

const IndexPage = ({ data }) => (
  <Layout absoluteHeader={true} simplePage={false}>
    <SEO title="Home" />
    <IntroSection marginSize={[8, 11]}>
      <Container>
        <h1>Enterprise-level cybersecurity for small businesses</h1>
        <ScrollButton
          to="request-evaluation"
          smooth={true}
          href="#request-evaluation"
        >
          Free Security Evaluation <FontAwesomeIcon icon="angle-double-right" />
        </ScrollButton>
      </Container>
    </IntroSection>
    <PitchSection>
      <div>
        <h2>
          Let us worry about your security, so that you can focus on your
          business.
        </h2>
        <p className="hero">
          Our simple, affordable cybersecurity packages help small businesses
          take their security to the next level.
        </p>
        <p>
          We provide the tools, monitoring, support, and training your business
          needs to operate safely in a dangerous world.
        </p>
      </div>
      <Sidebar>
        <p>
          <img
            src={logo}
            alt={`${data.site.siteMetadata.title} logo`}
            style={{ verticalAlign: 'text-bottom' }}
          />{' '}
          offers you...
        </p>
        <ul className="fa-ul">
          <li>
            <FontAwesomeIcon icon="check" listItem color={theme.primary2} />
            Packages tailor-made for small businesses
          </li>
          <li>
            <FontAwesomeIcon icon="check" listItem color={theme.primary2} />
            Best-in-class products
          </li>
          <li>
            <FontAwesomeIcon icon="check" listItem color={theme.primary2} />
            Solutions that scale with your business
          </li>
          <li>
            <FontAwesomeIcon icon="check" listItem color={theme.primary2} />
            Peace of mind to focus on your success
          </li>
        </ul>
      </Sidebar>
    </PitchSection>
    <Section style={{ backgroundColor: theme.primary6 }}>
      <h2>Small businesses are big targets.</h2>
      <p className="hero">
        Predators look for easy targets, and small businesses are low-hanging
        fruit for cybercriminals.
      </p>
      <ul className="fa-ul">
        <li>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            listItem
            color={theme.danger1}
            size="sm"
          />{' '}
          81% of all breaches happen to small and medium sized businesses
        </li>
        <li>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            listItem
            color={theme.danger1}
            size="sm"
          />{' '}
          1 in 5 small businesses will suffer a breach this year
        </li>
        <li>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            listItem
            color={theme.danger1}
            size="sm"
          />{' '}
          60% of small businesses go out of business within six months of an
          attack
        </li>
        <li>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            listItem
            color={theme.danger1}
            size="sm"
          />{' '}
          90% of small businesses don't protect company and customer information
          at all
        </li>
      </ul>
    </Section>
    <Section>
      <h2>And protecting yourself isn’t easy.</h2>
      <p className="hero">
        Staying secure requires a large investment of time and money, preventing
        you from focusing on revenue-generating activities that grow your
        business.
      </p>
      <ul className="fa-ul">
        <li>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            listItem
            color={theme.danger1}
            size="sm"
          />{' '}
          <strong>Antivirus is not enough.</strong> A wide range of specialized
          tools and knowledge is required to protect yourself against today’s
          cybercriminals. It’s hard to even know where to begin.
        </li>
        <li>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            listItem
            color={theme.danger1}
            size="sm"
          />{' '}
          <strong>Your budget is not endless.</strong> You need to pay for
          advanced tools, staff time, training, and monitoring. When not
          carefully managed, costs can balloon.
        </li>
        <li>
          <FontAwesomeIcon
            icon="exclamation-triangle"
            listItem
            color={theme.danger1}
            size="sm"
          />{' '}
          <strong>Your time is not abundant.</strong> Researching, configuring,
          and maintaining security systems; 24/7 monitoring; ongoing education…
          Securing your company requires dedicated, full-time employees.
        </li>
      </ul>
    </Section>
    <Section style={{ backgroundColor: theme.primary6 }}>
      <h2>We make security simple and affordable for small businesses.</h2>
      <p className="hero">
        We provide all-in-one, enterprise-level cybersecurity packages tailored
        specifically for the needs of small businesses.
      </p>
      <ul className="fa-ul">
        <li>
          <FontAwesomeIcon icon="check" listItem color={theme.primary2} /> We
          take on the cost and complexity of research, strategy, procurement,
          and implementation.
        </li>
        <li>
          <FontAwesomeIcon icon="check" listItem color={theme.primary2} />
          We stay on top of the field to make sure that as the threats evolve,
          your protection does too.
        </li>
        <li>
          <FontAwesomeIcon icon="check" listItem color={theme.primary2} /> We
          bring you premium products—and care—usually reserved for governments
          and large corporations.
        </li>
      </ul>
      <Button variant="ghost" as={Link} to="/services-and-pricing">
        View Our Services <FontAwesomeIcon icon="angle-double-right" />
      </Button>
    </Section>
    <Section id="request-evaluation">
      <EvaluationIntro>
        <h2>Get Your Free Security Evaluation.</h2>
        <p className="hero">
          You can focus on growing your business. Let us worry about protecting
          it.
        </p>
        <p>
          We'll perform a three-step guided security evaluation with you to help
          assess how well you're protected against today's threats.
        </p>
        <ol>
          <li>
            We schedule a call to get to know you and gather basic information.
          </li>
          <li>We take a closer look with a network or systems assessment.</li>
          <li>
            We walk you through a report of our findings and recommendations.
          </li>
        </ol>
      </EvaluationIntro>
      <EvaluationForm style={{ marginTop: theme.remScale[11] }} />
    </Section>
  </Layout>
)

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default IndexPage
