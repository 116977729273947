import React from 'react'
import styled from 'styled-components'

const StyledToggle = styled.div`
  p {
    margin-bottom: ${props => props.theme.remScale[3]};
  }

  input {
    position: absolute;
    left: -99999999px;
  }

  label {
    display: inline-block;
    padding: ${props => props.theme.remScale[2]}
      ${props => props.theme.remScale[6]};
    background-color: ${props => props.theme.white};
    border: 1px solid ${props => props.theme.neutral5};
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    box-shadow: inset 0 0 3px ${props => props.theme.shadow1};
    font-size: ${props => props.theme.remScale[4]};
    text-transform: capitalize;
    cursor: pointer;
    transition: all 100ms;
    user-select: none;

    &:hover {
      background-color: ${props => props.theme.neutral6};
    }
  }

  label:last-of-type {
    border-left: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  input:checked + label {
    background-color: ${props => props.theme.neutral1};
    border-color: ${props => props.theme.neutral1};
    box-shadow: 0 0 3px ${props => props.theme.neutral1};
    color: ${props => props.theme.white};
  }

  input:invalid + label:last-of-type {
    box-shadow: inset 0 0 3px ${props => props.theme.shadow1},
      8px 0 0 0 ${props => props.theme.danger5};
  }
`

export const Toggle = ({ question, ...props }) => (
  <StyledToggle>
    <p>{question}</p>
    {['yes', 'no'].map(value => {
      const id = `${props.name}-${value}`

      return (
        <React.Fragment key={id}>
          <input id={id} type="radio" value={value} {...props} />
          <label htmlFor={id}>
            <span className="sr-only">{question}</span> {value}
          </label>
        </React.Fragment>
      )
    })}
  </StyledToggle>
)
