import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { Button } from './styles/Button'
import { InfoMessage } from './styles/InfoMessage'
import { StyledForm } from './styles/StyledForm'
import { Fieldset } from './Fieldset'
import { Toggle } from './Toggle'
import { TextInput } from './TextInput'

export const EvaluationForm = props => (
  <StyledForm
    name="evaluation-sign-up"
    method="post"
    data-netlify="true"
    data-netlify-honeypot="fax-number"
    action="/evaluation-thank-you"
    {...props}
  >
    <input type="hidden" name="form-name" value="evaluation-sign-up" />

    <div style={{ display: 'none' }}>
      <TextInput question="Fax Number" autocomplete="off" />
    </div>

    <h3>Sign Up for Your Free Evaluation</h3>

    <InfoMessage>
      <FontAwesomeIcon icon="exclamation-circle" /> Fields marked with a thick
      border on the right must be completed.
    </InfoMessage>

    <Fieldset legend="Tell us about yourself">
      <TextInput question="Name" required />
      <TextInput question="Position" />
      <TextInput question="Email Address" type="email" required />
      <TextInput
        question="Primary Phone Number"
        type="tel"
        required
        pattern="^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$"
        className="w-half"
      />
    </Fieldset>

    <Fieldset legend="Tell us about your company">
      <TextInput question="Company Name" required />
      <TextInput
        question="Company Zip Code"
        required
        pattern="[0-9]{5}"
        className="w-third"
      />
      <TextInput
        question="Number of Employees"
        type="number"
        className="w-third"
      />
      <TextInput
        question="Number of Computers"
        type="number"
        className="w-third"
      />
    </Fieldset>

    <Fieldset legend="Tell us about your current security measures">
      {[
        {
          name: 'has-in-house',
          question: 'Do you have an in-house IT team?',
        },
        {
          name: 'allows-byod',
          question: 'May employees bring their own devices?',
        },
        {
          name: 'has-firewall',
          question: 'Do you have a firewall?',
        },
        {
          name: 'has-paid-antivirus',
          question: 'Do you use paid antivirus software?',
        },
      ].map(atts => (
        <Toggle key={atts.name} required {...atts} />
      ))}

      <Button as="button" type="submit">
        Get Your Evaluation <FontAwesomeIcon icon="thumbs-up" />
      </Button>

      <InfoMessage className="info invalid-only" variant="danger">
        <FontAwesomeIcon icon="exclamation-circle" /> Woah, there! Please
        complete all the required fields.
      </InfoMessage>
    </Fieldset>
  </StyledForm>
)
